import { type FC, useCallback, useRef } from 'react'
import { useIntl } from 'react-intl'

import { IconSearch } from '@redteclab/icons'

import { useExperimentsContext } from '../../../../../../experiments/components/ExperimentsContext'
import { EXPERIMENT_SERP_PRE_QUERY_SUGGESTIONS } from '../../../../../../experiments/model/EXPERIMENT_NAME_PER_TENANT'
import { useRunCallbackWhenNavigated } from '../../../../../../navigation-listener'
import { useHeaderSearchContext } from '../../../headerSearchContext'
import { useHeaderSearchPlaceholderText } from '../../../useHeaderSearchPlaceholderText'
import { handleKeyPressedOnSearchInput } from '../../HeaderSearchActiveSearchBox/HeaderSearchAlgoliaContainer/HeaderSearchResultsContainer/HeaderSearchResults/handleKeyPressedOnSearchInput'

export const HeaderSearchDesktopInput: FC = () => {
  const headerSearchContext = useHeaderSearchContext()
  const intl = useIntl()
  const { handleSubmitSearch, inputControls } = headerSearchContext
  const headerSearchPlaceholderText = useHeaderSearchPlaceholderText()
  const resultsPortal = useRef<HTMLDivElement | null>(null)

  useRunCallbackWhenNavigated(
    useCallback(() => {
      headerSearchContext.setSearchIsActive({
        value: false,
      })
    }, [headerSearchContext]),
  )

  const { addExperimentKeysForActivation } = useExperimentsContext()

  return (
    <>
      <form
        className="relative top-1/2 mb-4 flex -translate-y-1/2 flex-row"
        onSubmit={(event): void => {
          /**
           * We do "submitting" via js.
           * Form is left as fallback, for cases when javascript will fail to hydrate for some reason,
           * but user still be able to land on search results page.
           */
          event.preventDefault()
          handleSubmitSearch()
        }}
      >
        <input
          autoComplete="off"
          className="w-full rounded-l-full border-y border-l border-dark-accent bg-light-brand-low px-6 py-4 text-m outline-none transition-colors placeholder:text-m placeholder:font-normal placeholder:text-dark-primary-low hover:border-dark-brand focus:bg-light-primary-low"
          data-qa-id="search-box-query"
          name="query"
          onChange={inputControls.handleInputChange}
          onClick={() => {
            addExperimentKeysForActivation(
              EXPERIMENT_SERP_PRE_QUERY_SUGGESTIONS,
            )
          }}
          onFocus={(): void => {
            headerSearchContext.setSearchIsActive({
              portalResultsToElement: resultsPortal.current ?? undefined,
              value: true,
            })
          }}
          onKeyUp={(event): void => {
            handleKeyPressedOnSearchInput(event, headerSearchContext)
          }}
          placeholder={headerSearchPlaceholderText}
          type="search"
          value={inputControls.searchInputValue}
        />
        <button
          className="flex items-center whitespace-nowrap rounded-r-full bg-dark-redpoints px-3.5 text-m text-light-primary-strong hover:bg-dark-pressed-redpoints"
          data-qa-id="search-box-query-button"
          type="submit"
        >
          <IconSearch className="mr-2.5 size-8 fill-light-primary-strong" />

          <span className="hidden font-medium desktop:inline">
            {intl.formatMessage({ id: 'general.button.label.search' })}
          </span>
        </button>
      </form>
      <div ref={resultsPortal} />
    </>
  )
}
