import Image from 'next/image'
import NextLink from 'next/link'
import { type ElementType, type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { Skeleton } from 'base-ui'

import {
  CSW_INTERACTED_KEY,
  useSetSessionStorageValuesForOptimizelyTracking,
} from '../../../experiments/hooks'
import { useExperimentalAddStaticFileHost } from '../../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import { useGlobalConfigContext } from '../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  useCdnHostedImageLoader,
} from '../../../image'
import { urlGetRelativeUrl } from '../../../url-handling'
import { crossSellAccessorGetLocalizedProductDeepLink } from '../../model/crossSellAccessorGetLocalizedProductDeepLink'
import { crossSellAccessorGetSponsoredProductLink } from '../../model/crossSellAccessorGetSponsoredProductLink'
import { useCrossSellMetadataContext } from '../CrossSellMetatadaProvider'

import { CrossSellProductImageDiscount } from './CrossSellProductImageDiscount'

type CrossSellProductImageProps = {
  product?: CrossSellProduct
  slots?: {
    decorator?: ElementType
  }
}

const MAX_IMAGE_SIZE = 160

export const CrossSellProductImage: FC<CrossSellProductImageProps> = ({
  product,
  slots,
}) => {
  const loader = useCdnHostedImageLoader()
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()
  const { language, tenant } = useGlobalConfigContext()
  const trackClick =
    useSetSessionStorageValuesForOptimizelyTracking(CSW_INTERACTED_KEY)
  const metadata = useCrossSellMetadataContext()

  if (!product) {
    return (
      <Skeleton className="px-7 py-3.5 tablet:p-3.5" variant="rectangular">
        <div className="aspect-square w-full" />
      </Skeleton>
    )
  }

  const Decorator = slots?.decorator ?? CrossSellProductImageDiscount
  const sponsoredProductLink = crossSellAccessorGetSponsoredProductLink({
    metadata,
    product,
  })

  return (
    <NextLink
      className="relative px-7 py-3.5 after:absolute after:inset-0 after:bg-gradient-radial after:from-transparent after:via-transparent after:to-[var(--background-light-primary-medium)] tablet:p-3.5"
      data-qa-id={`form-product-slider-${product.id}-image-button`}
      href={
        sponsoredProductLink ??
        urlGetRelativeUrl(
          crossSellAccessorGetLocalizedProductDeepLink({
            language,
            product,
            tenant,
          }),
        )
      }
      onClick={trackClick}
      rel={sponsoredProductLink ? 'nofollow noopener' : undefined}
    >
      {sponsoredProductLink ? (
        <small className="absolute left-1/2 top-0 -translate-x-1/2 text-[#949494]">
          <FormattedMessage id="general.label.sponsored" />
        </small>
      ) : null}
      <div className="flex aspect-square max-h-[130px] w-full items-center justify-center">
        <Image
          alt={product.name}
          className="max-h-full w-auto max-w-full"
          data-analytics="item-image"
          height={MAX_IMAGE_SIZE}
          loader={loader}
          src={
            product.iconurl || addStaticCdnHostToUrl(IMAGE_PRODUCT_FALLBACK_SRC)
          }
          width={MAX_IMAGE_SIZE}
        />
      </div>
      <Decorator product={product} />
    </NextLink>
  )
}
