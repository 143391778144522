import { clsx } from 'clsx'
import { type FC } from 'react'

import { useAppRouterMigrationContext } from '../../../../app-router-migration'
import { ErrorBoundary } from '../../../../error-boundary'
import { HeaderSearchDesktop } from '../../header-search/components/HeaderSearchDesktop'
import { useHeaderDataContext } from '../../headerConfigContext'
import { HeaderNavigationProgressBar } from '../HeaderNavigationProgressBar/HeaderNavigationProgressBar'

import { HeaderMiddleSectionBrandLogo } from './HeaderMiddleSectionBrandLogo'
import { HeaderMiddleSectionIconsBox } from './HeaderMiddleSectionIconsBox'
import { HeaderMiddleSectionUsp } from './HeaderMiddleSectionUsp'

import styles from './HeaderMiddleSection.module.css'

interface HeaderMiddleSectionProps {
  isSticky: boolean
}

const HeaderMiddleSection: FC<HeaderMiddleSectionProps> = ({ isSticky }) => {
  const { shopLogos } = useHeaderDataContext()
  const { isAppRouter } = useAppRouterMigrationContext()

  const headerClassName = clsx(
    styles['header-middle-section'],
    'sticky top-0 z-30 hidden bg-light-primary-low tablet:block',
    isSticky && 'shadow-md',
  )

  return (
    <header className={headerClassName}>
      <div className="container relative flex py-2">
        <HeaderMiddleSectionBrandLogo
          className="relative mr-5 flex justify-center desktop:basis-48"
          isSticky={isSticky}
          shopLogos={shopLogos}
        />
        <div className="flex min-h-[56px] grow">
          <ErrorBoundary>
            <HeaderSearchDesktop className="relative mr-5 flex grow items-center" />
          </ErrorBoundary>
          <HeaderMiddleSectionUsp />
          <HeaderMiddleSectionIconsBox />
        </div>
      </div>
      {isSticky && !isAppRouter ? <HeaderNavigationProgressBar /> : null}
    </header>
  )
}

export { HeaderMiddleSection }
