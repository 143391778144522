'use client'

import { type FC } from 'react'

import { AppRouterMigrationHead } from '../app-router-migration'
import {
  SEO_META_DEDUPE_KEY_ROBOTS,
  SeoHeadDescriptionMetas,
  SeoHeadTitleMetas,
} from '../seo'

import { useStoryblokStory } from './context/StoryblokStoryContext'
import { type CmsAlternateUrlsMetaData } from './model/storyAccessors/CmsAlternateUrlsMetaData'
import { storyAccessorGetCanonicalUrl } from './model/storyAccessors/storyAccessorGetCanonicalUrl'
import { storyAccessorGetSeoRobotsValueOrDefault } from './model/storyAccessors/storyAccessorGetSeoRobotsValueOrDefault'

type Props = {
  hrefLangs: CmsAlternateUrlsMetaData[]
}

export const CmsSeo: FC<Props> = ({ hrefLangs }) => {
  const story = useStoryblokStory()

  const canonicalUrl = storyAccessorGetCanonicalUrl(story)
  const { description, title } = story.content
  const robotsValue = storyAccessorGetSeoRobotsValueOrDefault(story)

  return (
    <>
      {title ? <SeoHeadTitleMetas title={title} /> : null}
      {description ? (
        <SeoHeadDescriptionMetas description={description} />
      ) : null}
      <AppRouterMigrationHead>
        <link href={canonicalUrl} rel="canonical" />
        {hrefLangs.map((item) => (
          <link
            href={item.href}
            hrefLang={item.hrefLang}
            key={item.href}
            rel="alternate"
          />
        ))}
        {title ? <meta content={title} name="name" /> : null}
        <meta
          content={robotsValue}
          // key provided to dedupe/override one in global seo config WSAWA-6195
          key={SEO_META_DEDUPE_KEY_ROBOTS}
          name="robots"
        />
        <meta content={storyAccessorGetCanonicalUrl(story)} property="og:url" />
        <meta content="website" property="og:type" />
        <meta content="summary" name="twitter:card" />
      </AppRouterMigrationHead>
    </>
  )
}
