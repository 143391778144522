import { type FC } from 'react'

import { useAppRouterMigrationContext } from '../../../../app-router-migration'
import { useHeaderDataContext } from '../../headerConfigContext'
import { headerCmsBlockMenuAccessorGetNavigationMenuData } from '../../model/headerCmsBlockMenuAccessor'
import { HeaderNavigationProgressBar } from '../HeaderNavigationProgressBar/HeaderNavigationProgressBar'

import { HeaderNavigation } from './HeaderNavigation'

interface HeaderBottomSectionProps {
  isSticky: boolean
}

const HeaderBottomSection: FC<HeaderBottomSectionProps> = ({ isSticky }) => {
  const { cmsMenu } = useHeaderDataContext()
  const { isAppRouter } = useAppRouterMigrationContext()

  return (
    <div className="relative z-20 hidden bg-light-primary-low tablet:flex">
      <div className="mt-3 w-full bg-light-brand-high">
        <HeaderNavigation
          menuItems={headerCmsBlockMenuAccessorGetNavigationMenuData(cmsMenu)}
        />
      </div>
      {!isSticky && !isAppRouter && <HeaderNavigationProgressBar />}
    </div>
  )
}

export { HeaderBottomSection }
