import dynamic from 'next/dynamic'
import { type ComponentType } from 'react'

import { type StoryblokComponentProps } from '../model'
import {
  type StoryblokComponentName,
  type StoryblokComponentType,
} from '../model/storyblokComponents'

import { StoryblokAdBanner } from './StoryblokAdBanner/StoryblokAdBanner'
import { StoryblokHeadingV2 } from './StoryblokHeadingV2'

const StoryblokRichText = dynamic(async () =>
  import('./StoryblokRichText').then((module) => module.StoryblokRichText),
)

const StoryblokPage = dynamic(async () =>
  import('./StoryblokPage').then((module) => module.StoryblokPage),
)

const StoryblokAdvicePage = dynamic(async () =>
  import('./StoryblokAdvicePage').then((module) => module.StoryblokAdvicePage),
)

const StoryblokBlogArticle = dynamic(async () =>
  import('./StoryblokBlogArticle').then(
    (module) => module.StoryblokBlogArticle,
  ),
)

const StoryblokNotification = dynamic(async () =>
  import('./StoryblokNotification').then(
    (module) => module.StoryblokNotification,
  ),
)

const StoryblokSection = dynamic(async () =>
  import('./StoryblokSection').then((module) => module.StoryblokSection),
)

const StoryblokImageTextHorizontalCard = dynamic(async () =>
  import('./StoryblokImageTextHorizontalCard').then(
    (module) => module.StoryblokImageTextHorizontalCard,
  ),
)

const StoryblokButton = dynamic(async () =>
  import('./StoryblokButton').then((module) => module.StoryblokButton),
)

const StoryblokHeaderBanner = dynamic(async () =>
  import('./StoryblokHeaderBanner').then(
    (module) => module.StoryblokHeaderBanner,
  ),
)

const StoryblokCardCarousel = dynamic(async () =>
  import('./StoryblokCardCarousel').then(
    (module) => module.StoryblokCardCarousel,
  ),
)

const StoryblokImageCard = dynamic(async () =>
  import('./StoryblokImageCard').then((module) => module.StoryblokImageCard),
)

const StoryblokCategorySection = dynamic(async () =>
  import('./StoryblokCategorySection').then(
    (module) => module.StoryblokCategorySection,
  ),
)

const StoryblokVoucherCard = dynamic(async () =>
  import('./StoryblokVoucherCard').then(
    (module) => module.StoryblokVoucherCard,
  ),
)

const StoryblokColumnCard = dynamic(async () =>
  import('./StoryblokColumnCard').then((module) => module.StoryblokColumnCard),
)

const StoryblokStepStar = dynamic(async () =>
  import('./StoryblokStepStar').then((module) => module.StoryblokStepStar),
)

const StoryblokImage = dynamic(async () =>
  import('./StoryblokImage').then((module) => module.StoryblokImage),
)

const StoryblokImageV2 = dynamic(async () =>
  import('./StoryblokImageV2').then((module) => module.StoryblokImageV2),
)

const StoryblokSpacing = dynamic(async () =>
  import('./StoryblokSpacing').then((module) => module.StoryblokSpacing),
)

const StoryblokFlexRow = dynamic(async () =>
  import('./StoryblokFlexRow').then((module) => module.StoryblokFlexRow),
)

const StoryblokProductBattery = dynamic(async () =>
  import('./StoryblokProductBattery').then(
    (module) => module.StoryblokProductBattery,
  ),
)

const StoryblokPlainTextFaq = dynamic(async () =>
  import('./StoryblokPlainTextFaq').then(
    (module) => module.StoryblokPlainTextFaq,
  ),
)

const StoryblokCategoryWideTeaserAd = dynamic(async () =>
  import('./StoryblokCategoryWideTeaserAd').then(
    (module) => module.StoryblokCategoryWideTeaserAd,
  ),
)

const StoryblokServicesList = dynamic(async () =>
  import('./StoryblokServicesList/StoryblokServicesList').then(
    (module) => module.StoryblokServicesList,
  ),
)

const StoryblokCard = dynamic(async () =>
  import('./StoryblokCard/StoryblokCard').then(
    (module) => module.StoryblokCard,
  ),
)

type StoryblokComponentMap = {
  [key in StoryblokComponentName]: ComponentType<
    StoryblokComponentProps<Extract<StoryblokComponentType, { component: key }>>
  >
}

export const STORYBLOK_COMPONENTS_MAP = {
  Accordion: dynamic(async () =>
    import('./StoryblokAccordion').then((module) => module.StoryblokAccordion),
  ),
  AccordionStack: dynamic(async () =>
    import('./StoryblokAccordionStack').then(
      (module) => module.StoryblokAccordionStack,
    ),
  ),
  AdBanner: StoryblokAdBanner,
  AdviceCard: dynamic(async () =>
    import('./StoryblokAdviceCard').then(
      (module) => module.StoryblokAdviceCard,
    ),
  ),
  AdvicePage: StoryblokAdvicePage,
  BlogArticle: StoryblokBlogArticle,
  BrandImageCard: dynamic(async () => {
    return import('./StoryblokBrandImageCard/StoryblokBrandImageCard').then(
      (module) => module.StoryblokBrandImageCard,
    )
  }),
  Button: StoryblokButton,
  ButtonV2: dynamic(async () =>
    import('./StoryblokButtonV2').then((module) => module.StoryblokButtonV2),
  ),
  Card: StoryblokCard,
  CardCarousel: StoryblokCardCarousel,
  CategorySection: StoryblokCategorySection,
  CategoryWideTeaserAd: StoryblokCategoryWideTeaserAd,
  ColumnCard: StoryblokColumnCard,
  ContainerLink: dynamic(async () => {
    return import('./StoryblokContainerLink/StoryblokContainerLink').then(
      (module) => module.StoryblokContainerLink,
    )
  }),
  CrossSellGrid: dynamic(async () =>
    import('./StoryblokCrossSellGrid').then(
      (module) => module.StoryblokCrossSellGrid,
    ),
  ),
  CrossSellSlider: dynamic(async () =>
    import('./StoryblokCrossSellSlider').then(
      (module) => module.StoryblokCrossSellSlider,
    ),
  ),
  Flex: dynamic(async () => {
    return import('./StoryblokFlex/StoryblokFlex').then(
      (module) => module.StoryblokFlex,
    )
  }),
  FlexRow: StoryblokFlexRow,
  Grid: dynamic(async () =>
    import('./StoryblokGrid').then((module) => module.StoryblokGrid),
  ),
  GridCell: dynamic(async () =>
    import('./StoryblokGrid').then((module) => module.StoryblokGridCell),
  ),
  HeaderBanner: StoryblokHeaderBanner,
  HeadingV2: StoryblokHeadingV2,
  Image: StoryblokImage,
  ImageCard: StoryblokImageCard,
  ImageTextHorizontalCard: StoryblokImageTextHorizontalCard,
  ImageV2: StoryblokImageV2,
  MostSearchedKeywords: dynamic(async () => {
    return import(
      './StoryblokMostSearchedKeywords/StoryblokMostSearchedKeywords'
    ).then((module) => module.StoryblokMostSearchedKeywords)
  }),
  Notification: StoryblokNotification,
  Page: StoryblokPage,
  Pill: dynamic(async () =>
    import('./StoryblokPill/StoryblokPill').then(
      (module) => module.StoryblokPill,
    ),
  ),
  PlainTextFaq: StoryblokPlainTextFaq,
  ProductBattery: StoryblokProductBattery,
  RichText: StoryblokRichText,
  Section: StoryblokSection,
  SectionHeading: dynamic(async () =>
    import('./StoryblokSectionHeading').then(
      (module) => module.StoryblokSectionHeading,
    ),
  ),
  SectionV2: dynamic(async () =>
    import('./StoryblokSectionV2').then((module) => module.StoryblokSectionV2),
  ),
  ServicesList: StoryblokServicesList,
  ServicesListItem: dynamic(async () =>
    import('./StoryblokServicesList/StoryblokServicesListItem').then(
      (module) => module.StoryblokServicesListItem,
    ),
  ),
  Slider: dynamic(async () =>
    import('./StoryblokSlider').then((module) => module.StoryblokSlider),
  ),
  Spacing: StoryblokSpacing,
  StepStar: StoryblokStepStar,
  TextCard: dynamic(async () => {
    return import('./StoryblokTextCard/StoryblokTextCard').then(
      (module) => module.StoryblokTextCard,
    )
  }),
  VoucherCard: StoryblokVoucherCard,
} satisfies Partial<StoryblokComponentMap>
