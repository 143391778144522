'use client'

import { type FC } from 'react'

import { AppRouterMigrationHead } from '../../app-router-migration'
import { useExperimentalAddStaticFileHost } from '../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'

import { useSeoGlobalConfigContext } from './context/seoGlobalConfigContext'

/**
 * IMPORTANT: notice that key is used, it shall always be provided, because this is how Next will understand which to dedupe.
 * e.g.
 * on home page:
 * <meta title='homePage' key='title'>
 *
 * on product page:
 * <meta title='productPage' key='title'>
 *
 * if key not provided, Next will not dedupe and will render both,
 * if key is matching next will use the last one
 */
export const SeoAppMetaAndLinkTags: FC = () => {
  const seoConfig = useSeoGlobalConfigContext()
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()

  return (
    <AppRouterMigrationHead>
      {seoConfig.metas.map((metaTag) => {
        return <meta {...metaTag} key={metaTag.key} />
      })}
      {seoConfig.links.map((linkTag) => {
        let modifiedLinkTag = linkTag

        if (linkTag.rel === 'icon') {
          modifiedLinkTag = {
            ...linkTag,
            href: addStaticCdnHostToUrl(linkTag.href),
          }
        }

        return <link {...modifiedLinkTag} key={modifiedLinkTag.key} />
      })}
    </AppRouterMigrationHead>
  )
}
