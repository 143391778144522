import { TENANT } from '../tenant'

import { type AutoRefreshConfig } from './model/adServerTypes'

export const AD_BANNER_STATUS_DATA_ATTR = 'data-as-status'
export const AD_BANNER_CONTENT_DATA_ATTR = 'data-as-content'
export const AD_BANNER_AUTO_LOAD_DATA_ATTR = 'data-auto-load'
export const AD_BANNER_CONTAINER_DATA_ATTR = 'data-as-container'
export const AD_BANNER_LOCAL_STORAGE_USER_CLOSED_FLAG_KEY = 'ad_banner_flag'
export const AD_BANNER_FLIGHT_ID_DATA_ATTR = 'data-as-flight-id'
export const AD_BANNER_TOP_BANNER_DISPLAY_DATA_ATTR = 'data-as-top-banner'
export const AD_SERVER_MEDIA_CODE_SEARCH_PARAM = 'mediacode'
export const AD_SERVER_XSELL_PRODUCT_ID_DATA_ATTR = 'data-as-xsell-product-id'
export const AD_SERVER_VISIBLE_EVENT_TYPE = 30

export const AD_SERVER_BROWSER_ID_CONVERSION_COOKIE = 'asbidc'
export const AD_SERVER_BROWSER_ID_TARGETING_COOKIE = 'asbidt'
export const AD_SERVER_USER_ID_COOKIE = 'asuid'

export const AD_SERVER_CAMPAIGN_CODE_COOKIE = 'as-campaign'

// 90 days in seconds
export const AD_SERVER_BROWSER_ID_CONVERSION_COOKIE_MAX_AGE = 7_776_000
// 7 days in seconds
export const AD_SERVER_BROWSER_ID_TARGETING_COOKIE_MAX_AGE = 604_800

export const AD_BANNER_WIDTH_DATA_ATTR = 'data-as-width'
export const AD_BANNER_HEIGHT_DATA_ATTR = 'data-as-height'
export const AD_BANNER_TAGS_DATA_ATTR = 'data-as-tags'
export const AD_BANNER_AUTO_REFRESH_DATA_ATTR = 'data-as-auto-refresh'
export const AD_BANNER_PDP_DEFAULT = 'pds-b1'
// product page: RX message
export const AD_BANNER_PDP_RX = 'pds-b1-rx'

// 1 day in seconds
export const AD_SERVER_CAMPAIGN_CODE_EXPIRY_IN_MS = 86_400_000

/**
 * We use `ad-exp-` prefix to introduce new experiments on all ad products (banners, xsell and search) without changing the code.
 * By using this prefix we get the ad-server related experiments and use them for:
 * 1. Activate the experiment on the optimizely
 * 2. Pass the experiment name and variant (as a part of the ad-server context)
 * to the ad-server to display the banner for the specific experiment variant
 *
 * Some more information how to set up the ad-server experiments:
 * https://wiki.shop-apotheke.com/pages/viewpage.action?pageId=335087751
 */
export const AD_SERVER_EXPERIMENTS_PREFIX = 'ad-exp-'

type Dimensions = {
  height: number
  width: number
}

type Sizes = {
  large: Dimensions
  small: Dimensions
}

export const AD_BANNER_PDP_DEFAULT_DIMENSIONS: Sizes = {
  large: { height: 200, width: 480 },
  small: { height: 135, width: 347 },
}

export const AD_BANNER_PDP_RX_DIMENSIONS: Sizes = {
  large: { height: 210, width: 480 },
  small: { height: 140, width: 347 },
}

export const AD_BANNER_AUTO_REFRESH_CONFIG: AutoRefreshConfig = {
  intervalMs: 20_000,
  maxCount: 10,
}

export const AD_BANNER_AUTO_REFRESH_TENANTS_MAP: Partial<
  Record<TENANT, string[]>
> = {
  [TENANT.BE]: ['hp-b1', 'cp-b1'],
  [TENANT.FR]: ['hp-b1'],
}
