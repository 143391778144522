import { type ISbStoryData } from '@storyblok/react'

import { type StoryblokContentTypePage } from '../StoryblokContentTypePage'

const DEFAULT_ROBOTS_VALUE = 'index, follow, noodp'

export const storyAccessorGetSeoRobotsValueOrDefault = (
  story: ISbStoryData<StoryblokContentTypePage>,
): string => {
  const { seoRobots } = story.content

  if (!seoRobots) {
    return DEFAULT_ROBOTS_VALUE
  }

  return String(seoRobots)
}
