import { usePathname } from 'next/navigation'
import { useCallback, useEffect, useRef, useState, useTransition } from 'react'

import { type MetadataContent } from '@redteclab/api/clients/adserver-api'

import { useExperimentsContext } from '../../../experiments/components/ExperimentsContext'
import { experimentAccessorIsAdServerEnabled } from '../../../experiments/model/experimentAccessor'
import { useGlobalConfigContext } from '../../../global-config'
import { AD_BANNER_AUTO_REFRESH_TENANTS_MAP } from '../../adServerConst'
import {
  AD_SERVER_ON_PLACED_EVENT,
  adServerSlotsPushLoadCommand,
} from '../../adServerSlots'

type BannerProps = {
  height: number
  id: string
  width: number
}

type BannersState = {
  height: number
  id: string
  metadata: MetadataContent | undefined
  width: number
}

type BannersHtmlContent = {
  bannerElement: HTMLDivElement
  pushed: boolean
}

// unique identifier for the banner that we will use to keep state data and ref data in sync
const getBannerId = ({ height, id, width }: BannerProps): string => {
  return `${id}_${width}_${height}`
}

const createBannerMainDiv = ({
  autoRefresh,
  height,
  id,
  width,
}: BannerProps & {
  autoRefresh?: boolean
}): HTMLDivElement => {
  const mainDiv = document.createElement('div')

  mainDiv.dataset.asHeight = `${height}`
  mainDiv.dataset.asTags = id
  mainDiv.dataset.asWidth = `${width}`
  mainDiv.className = 'as-placeholder'
  mainDiv.style.aspectRatio = `${width / height}`
  mainDiv.style.width = `${width}px`
  mainDiv.style.maxWidth = '100%'

  if (autoRefresh) {
    mainDiv.dataset.asAutoRefresh = 'true'
  }

  return mainDiv
}

// pure js banner template that will be passed to adServer api
const createBannerWrapper = ({
  autoRefresh,
  height,
  id,
  setMetadataCallback,
  width,
}: BannerProps & {
  setMetadataCallback: (bannerId: string, metadata: MetadataContent) => void
} & { autoRefresh?: boolean }): HTMLDivElement => {
  // Create the main div element
  const mainDiv = createBannerMainDiv({
    autoRefresh,
    height,
    id,
    width,
  })

  // Create the inner div element
  const innerDiv = document.createElement('div')
  innerDiv.dataset.asContainer = ''

  // Append the inner div to the main div
  mainDiv.append(innerDiv)

  const adServerOnPlaced = (event: CustomEvent<MetadataContent>): void => {
    mainDiv.dataset.asFlightId = `${event.detail.flightId}`
    setMetadataCallback(getBannerId({ height, id, width }), event.detail)

    if (event.detail.customData?.topBannerDisplay) {
      mainDiv.dataset.asTopBanner = event.detail.customData.topBannerDisplay
    }
  }

  mainDiv.addEventListener(AD_SERVER_ON_PLACED_EVENT, adServerOnPlaced, {
    once: !autoRefresh,
  })

  return mainDiv
}

type BannerData =
  | ({ bannerElement: HTMLDivElement | undefined } & BannersState)
  | null

export type AdServerState = {
  getBanner: ({ height, id, width }: BannerProps) => BannerData
  initBanners: (bannersListToLoad: BannerProps[]) => void
}

export const useAdServerState = (): AdServerState => {
  // Stores banners data and matadata
  const [banners, setBanners] = useState<Record<string, BannersState>>({})
  // In sync with banners, stores banners html template that will be pushed to adServer api
  const bannersHtml = useRef<Record<string, BannersHtmlContent>>({})
  const pathname = usePathname()
  const [, startTransition] = useTransition()
  const { tenant } = useGlobalConfigContext()
  const { experiments } = useExperimentsContext()
  const isAdServerEnabled = experimentAccessorIsAdServerEnabled(experiments)

  // wait for batch state update to send only one push command
  useEffect(() => {
    if (!isAdServerEnabled) {
      return
    }

    const bannersToFetch = []
    for (const [key, bannerEntry] of Object.entries(bannersHtml.current)) {
      if (!bannerEntry.pushed) {
        bannersToFetch.push(bannerEntry.bannerElement)
        // pushed will help us identify which banners were already pushed to adServer api,
        // works similar to previous state implementation
        bannersHtml.current[key].pushed = true
      }
    }

    if (bannersToFetch.length > 0) {
      adServerSlotsPushLoadCommand({ slots: bannersToFetch })
    }
  }, [banners, isAdServerEnabled])

  // adServer api comunicate using events, we need to update the banner metadata when the banner is loaded
  const setBannerMetadata = useCallback(
    (bannerId: string, metadata: MetadataContent): void => {
      startTransition(() => {
        setBanners((previousBanners) => {
          const newBanners = { ...previousBanners }
          if (bannerId in newBanners) {
            newBanners[bannerId].metadata = metadata
          }

          return newBanners
        })
      })
    },
    [setBanners, startTransition],
  )

  // initializes multiple banners at once, usefull to call it once per page at the begining if we already know all the banners
  const initBanners = useCallback(
    (bannersList: BannerProps[]): void => {
      if (!isAdServerEnabled) {
        return
      }

      const isLandingPage = pathname?.includes('/lp/')

      if (isLandingPage) {
        return
      }

      startTransition(() => {
        setBanners((previousBanners) => {
          const newBanners = { ...previousBanners }

          for (const { height, id, width } of bannersList) {
            const bannerId = getBannerId({ height, id, width })

            if (!(bannerId in newBanners)) {
              const autoRefreshBannerIds =
                AD_BANNER_AUTO_REFRESH_TENANTS_MAP[tenant]
              const autoRefresh = autoRefreshBannerIds
                ? autoRefreshBannerIds.includes(id)
                : false
              const bannerElement = createBannerWrapper({
                autoRefresh,
                height,
                id,
                setMetadataCallback: setBannerMetadata,
                width,
              })

              newBanners[bannerId] = {
                height,
                id,
                metadata: undefined,
                width,
              }

              bannersHtml.current[bannerId] = {
                bannerElement,
                pushed: false,
              }
            }
          }

          return newBanners
        })
      })
    },
    [
      isAdServerEnabled,
      setBannerMetadata,
      setBanners,
      pathname,
      startTransition,
      tenant,
    ],
  )

  // retrieves combined banner data stored on state and ref (basic data, metadata and html template)
  const getBanner = useCallback(
    ({ height, id, width }: BannerProps): BannerData => {
      const bannerId = getBannerId({ height, id, width })

      if (!(bannerId in banners)) {
        return null
      }

      return {
        ...banners[bannerId],
        bannerElement: bannersHtml.current[bannerId].bannerElement,
      }
    },
    [banners],
  )

  return {
    getBanner,
    initBanners,
  }
}
