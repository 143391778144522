export const ExperimentPageType = {
  Account: 'account',
  Cart: 'cart',
  CartIntermediate: 'cartintermediate',
  Category: 'category',
  Checkout: 'checkout',
  Contact: 'contact',
  DamagedProducts: 'damaged-products',
  Default: 'default',
  Error: 'error',
  ErxLandingPage: 'erx_landing_page',
  ForgotPassword: 'forgot-password',
  Homepage: 'homepage',
  Interaction: 'interaction',
  InteractionResult: 'interaction_result',
  Login: 'login',
  Marketing: 'marketing',
  Newsletter: 'newsletter',
  NewsletterUnsubscribe: 'newsletter_unsubscribe',
  NfcLandingPage: 'nfc_landing_page',
  Notepad: 'notepad',
  OrdersList: 'orders_list',
  Product: 'product',
  Redpoints: 'redpoints',
  Search: 'search',
} as const

export type ExperimentPageType =
  (typeof ExperimentPageType)[keyof typeof ExperimentPageType]
