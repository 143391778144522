'use client'

import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type FC,
  useEffect,
  useRef,
} from 'react'

import {
  type CrossSellProduct,
  type Metadata,
} from '@redteclab/api/clients/retail-media-xsell-service'

import { AD_SERVER_XSELL_PRODUCT_ID_DATA_ATTR } from '../../../ad-server'
import { ExperimentCtaButtonOnAllCsw } from '../../../experiments/components/ExperimentCtaButtonOnAllCsw'
import {
  EXPERIMENTS_TRACK_EVENT_PDP_CSW_PRODUCT,
  experimentsTrackEvent,
} from '../../../experiments/eventTracking'
import { useGetExperiment } from '../../../experiments/hooks'
import { EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW } from '../../../experiments/model/EXPERIMENT_NAME'
import { experimentAccessorNonDefault } from '../../../experiments/model/experimentAccessor'
import { useGlobalConfigContext } from '../../../global-config'
import { useCrossSellMetadataContext } from '../CrossSellMetatadaProvider'

import { CrossSellProductImage } from './CrossSellProductImage'
import { CrossSellProductName } from './CrossSellProductName'
import { CrossSellProductPrice } from './CrossSellProductPrice'
import { CrossSellProductQuantity } from './CrossSellProductQuantity'
import { CrossSellProductRating } from './CrossSellProductRating'

type CrossSellHorizontalSliderItemProps =
  ComponentPropsWithoutRef<'section'> & {
    product?: CrossSellProduct
    slots?: {
      image?: ElementType
    }
  }

export const CrossSellProductCard: FC<CrossSellHorizontalSliderItemProps> = ({
  className,
  product,
  slots,
  ...rest
}) => {
  const ImageComponent = slots?.image ?? CrossSellProductImage
  const config = useGlobalConfigContext()
  const metadata = useCrossSellMetadataContext()
  const metadataContent = product?.id
    ? metadata?.[product.id as keyof Metadata]
    : undefined

  const experimentCtaButtonOnAllCsw = useGetExperiment(
    EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW,
  )

  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    const sliderItem = ref.current

    if (
      !sliderItem ||
      !experimentAccessorNonDefault(experimentCtaButtonOnAllCsw)
    ) {
      return undefined
    }

    const trackSection = (event: Event): void => {
      const target = event.target as HTMLElement
      if (!target.closest('[data-qa-id="add-to-cart-button"]')) {
        experimentsTrackEvent(config, EXPERIMENTS_TRACK_EVENT_PDP_CSW_PRODUCT)
      }
    }
    sliderItem.addEventListener('click', trackSection)

    return (): void => {
      sliderItem.removeEventListener('click', trackSection)
    }
  }, [config, experimentCtaButtonOnAllCsw])

  const sectionProps = {
    /*
     * Re-ranked / injected elements from ad server needs to be marked here
     * They're being grabbed in ProductAvailableCrossSellSliderWidget.tsx
     * and pushed into ad server data layer
     */
    [AD_SERVER_XSELL_PRODUCT_ID_DATA_ATTR]:
      metadataContent && product?.id ? product.id : undefined,
    className: clsx(
      'mb-2 flex flex-1 flex-col overflow-hidden rounded-2xl shadow-md',
      className,
    ),
    'data-product-id': product?.id,
    ref,
    ...rest,
  }

  return (
    <section {...sectionProps}>
      <ImageComponent product={product} />
      <div className="flex flex-1 flex-col justify-between gap-1 px-2 py-2.5">
        <CrossSellProductName product={product} />
        <div className="flex flex-col gap-1">
          <CrossSellProductRating product={product} />
          <CrossSellProductQuantity product={product} />
          <CrossSellProductPrice product={product} />
          <ExperimentCtaButtonOnAllCsw product={product} />
        </div>
      </div>
    </section>
  )
}
