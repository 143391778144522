import { EXPERIMENT_VARIATION } from '../../model/EXPERIMENT_VARIATION'

export const EXPERIMENT_ALGOLIA_SUGGEST_OPTIMISATION_DEBOUNCE_REQUEST_TIME = 500
const EXPERIMENT_ALGOLIA_SUGGEST_OPTIMISATION_TYPEAHEAD_MIN_QUERY_LEN = 3
const TYPEAHEAD_MIN_QUERY_LEN = 2

export const getMinSearchQueryLength = (
  isEnabled: boolean,
  variant?: EXPERIMENT_VARIATION,
): number => {
  if (
    isEnabled &&
    (variant === EXPERIMENT_VARIATION.V1 || variant === EXPERIMENT_VARIATION.V3)
  ) {
    return EXPERIMENT_ALGOLIA_SUGGEST_OPTIMISATION_TYPEAHEAD_MIN_QUERY_LEN
  }

  return TYPEAHEAD_MIN_QUERY_LEN
}
